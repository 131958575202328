<template>
  <div>
    <!-- <this-header /> -->
    <div class="container-sm-lot pl-1 pr-1">
      <div class="d-flex align-items-center justify-content-between">
        <router-link :to="{name : 'first-deposit'}">
          <img
            src="/back.svg"
            alt="back"
          >
        </router-link>

        <h3 class="text mb-0">
          QR 코드
        </h3>
      </div>

      <div>
        <div class="text-center mt-1">
          <h1 class="mb-0">
            결제 방법 선택
          </h1>

          <small class="text-warning">
            시스템은 1~2분 내에 자동으로 게임 크레딧을 충전합니다.
          </small>
        </div>
      </div>

      <div class="card mt-2 p-2">
        <div>
          <div class="text-center">
            <h5 class="font-weight-bolder text-primary">
              금액을 지불해 주세요
            </h5>

            <h3 class="font-weight-bolder text-primary my-2">
              20,000 ₩
            </h3>

            <p class="mb-0">
              지정된 시간 내의 QR 코드
            </p>

            <p class="font-weight-bolder text-warning mb-2">
              남은 시간 05:27
            </p>

            <img
              src="/qr.jpg"
              alt="card"
              height="250"
            >
            <div class="mt-3">
              <h5 class="font-weight-bolder text-primary">
                휴대폰 화면 캡처 >> QR 사진을 찍고 은행 앱을 통해 결제하세요.
              </h5>

              <p class="mb-0 text-warning">
                결제가 완료되면 잠시만 기다려주세요.
              </p>

              <p class="mb-0 text-warning">
                시스템이 자동으로 주문을 확인합니다.
              </p>
            </div>
            <button class="mt-3 btn-copy">
              <i class="fal fa-download mr-50" />  계좌번호 복사
            </button>
          </div>

        </div>
      </div>
    </div>

    <ThisFooter />
    <!-- </div> -->
  </div>
</template>

<script>
import ThisFooter from '../components/ThisFooter.vue'

export default {
  name: 'Home',
  components: {
    // ThisHeader,
    ThisFooter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  created() {
    if (!this.userData) {
      this.$router.push({ name: 'home' })
    }
  },
  mounted() {

  },
  methods: {

  },
}
</script>

  <style scoped>
  </style>
